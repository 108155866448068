/* eslint-disable react/no-unstable-nested-components */
'use client'
import React, { useState } from 'react'
import { ParsedCountry, PhoneInput } from 'react-international-phone'
import 'react-international-phone/style.css'
import './FormSelectPhone.scss'
import FormGroup from '../forms/FormGroup'
import { Label } from '../forms/Label'
import InputGroup from '../forms/InputGroup'
import InputErrorText from '../forms/InputErrorText'

interface FormSelectPhoneProps extends React.InputHTMLAttributes<HTMLInputElement> {
  name: string
  label?: string | React.ReactNode
  placeholder: string
  value: string | number
  isValid?: boolean
  errorText?: string
  required?: boolean
  onChange: (value: any) => any
  onChangeCountry?: (value: any) => any
}

export const FormSelectPhone = React.forwardRef(
  (
    {
      name,
      label = '',
      placeholder,
      value,
      isValid = true,
      required,
      className,
      errorText,
      onChange,
      onChangeCountry
    }: FormSelectPhoneProps,
    ref
  ) => {
    const [isFocused, setIsFocused] = useState(false)

    const handleFocus = () => {
      setIsFocused(true)
    }

    const handleBlur = () => {
      setIsFocused(false)
    }

    const handleOnChange = (
      phone: string,
      meta: {
        country: ParsedCountry
        inputValue: string
      }
    ) => {
      onChange(phone)
      if (onChangeCountry) onChangeCountry(meta.country.name)
    }

    return (
      <FormGroup className={className}>
        <Label
          isFocused={isFocused}
          isValid={isValid}
          hidden={Boolean(label)}
          htmlFor={name}
          style={{ zIndex: 4, transform: 'translateX(45px)' }}
        >
          {label}
        </Label>
        <InputGroup>
          <PhoneInput
            name={name}
            defaultCountry="mx"
            value={String(value)}
            className={isValid ? '' : 'invalid'}
            inputClassName={isValid ? '' : 'border-red-500'}
            onFocus={handleFocus}
            onBlur={handleBlur}
            onChange={handleOnChange}
            required={required}
            placeholder={isFocused ? '' : required ? '* ' + placeholder : placeholder}
          />
          <InputErrorText>{errorText}</InputErrorText>
        </InputGroup>
      </FormGroup>
    )
  }
)

FormSelectPhone.displayName = 'FormSelectPhone'
export default FormSelectPhone
