import { FormError } from '@/components/shared/errors'
import { useSearchParams } from 'next/navigation'
import { useState } from 'react'
import Swal, { SweetAlertOptions } from 'sweetalert2'

export const useGetUtmQueryParams = () => {
  try {
    const searchParams = useSearchParams()
    return {
      utm_source: searchParams?.get('utm_source'),
      utm_medium: searchParams?.get('utm_medium'),
      utm_campaign: searchParams?.get('utm_campaign')
    }
  } catch (error) {
    return {}
  }
}

export const useSubmitForm = ({
  service,
  aditionalPayloadData,
  onErrorCallback,
  onSuccessCallback
}: {
  service: (data: Record<string, any>) => Promise<void>
  aditionalPayloadData: Record<string, any>
  onErrorCallback: ({ error, reset }: { error: FormError; reset: () => any }) => any
  onSuccessCallback?: ({ reset }: { reset: () => any }) => any
}) => {
  const [isLoading, setIsLoading] = useState(false)
  const handleSubmit = async (data: Record<string, any>, reset: () => any) => {
    if (isLoading) return
    setIsLoading(true)

    let operationSuccess = true
    const error = ''
    try {
      await service({
        ...aditionalPayloadData,
        ...data
      })
        .then((response: any) => {
          if (response?.error) {
            operationSuccess = false
            throw response.error
          }
        })
        .catch((error: any) => {
          operationSuccess = false
          throw error
        })
    } catch (error) {
      operationSuccess = false
      if (!operationSuccess && onErrorCallback) {
        if (error instanceof FormError) {
          onErrorCallback({ error, reset })
          return
        } else if (error instanceof Error) {
          onErrorCallback({ error: new Error(String(error)), reset })
          return
        } else {
          onErrorCallback({ error: new Error(String(error)), reset })
          return
        }
      }
    } finally {
      setIsLoading(false)
    }
    if (onSuccessCallback) onSuccessCallback({ reset })
  }
  return { handleSubmit, isLoading }
}

export const showUIAlert = (SweetAlertConfiguration: SweetAlertOptions) => {
  void Swal.fire({
    customClass: {
      title: 'text-blue-darkest text-medium font-semibold',
      confirmButton: 'px-3 py-2 bg-blue-light text-white rounded-md w-full',
      actions: 'flex justify-center gap-2 w-full px-5'
    },
    buttonsStyling: false,
    ...SweetAlertConfiguration
  })
}
