'use client'
import { Button } from '@/components/atoms/Button'
import FormGroup from '@/components/atoms/forms/FormGroup'
import { IInputConfiguration } from '@/components/atoms/forms/InputConfigs'
import InputErrorText from '@/components/atoms/forms/InputErrorText'
import { Label } from '@/components/atoms/forms/Label'
import FormCheckbox from '@/components/atoms/inputs/FormCheckbox'
import FormInput from '@/components/atoms/inputs/FormInput'
import FormInputFile from '@/components/atoms/inputs/FormInputFile'
import FormSelect from '@/components/atoms/inputs/FormSelect'
import FormSelectPhone from '@/components/atoms/inputs/FormSelectPhone'
import FormTextarea from '@/components/atoms/inputs/FormTextarea'
import { classNames } from '@/components/shared/classNames'
import { yupResolver } from '@hookform/resolvers/yup'
import Link from 'next/link'
import { Controller, useForm } from 'react-hook-form'

const getDefaultValues = (InputsConfig: IInputConfiguration[]) => {
  return InputsConfig.reduce((acc: Record<string, any>, input) => {
    if (input.defaultValue) acc[input.name] = input.defaultValue
    else if (['input', 'textarea', 'select'].includes(input.type)) acc[input.name] = input.defaultValue ?? ''
    else if (input.type === 'checkbox') acc[input.name] = input.defaultValue ?? false
    return acc
  }, {})
}

const getFormGroups = (
  InputsConfig: IInputConfiguration[],
  control: any,
  register: any,
  resetField: any,
  errors: any,
  uuid = ''
) => {
  return InputsConfig.map((inputForm: IInputConfiguration, index) => {
    if (inputForm.type === 'input') {
      if (inputForm.subtype === 'tel') {
        return (
          <Controller
            key={index}
            render={({ field }: any) => (
              <FormSelectPhone
                {...field}
                label={inputForm.label}
                type={inputForm.subtype}
                required={inputForm.required}
                disabled={inputForm.disabled}
                placeholder={inputForm.placeholder}
                className={classNames(inputForm.className, errors[inputForm.name] ? 'invalid' : '')}
                errorText={errors[inputForm.name]?.message}
                isValid={!errors[inputForm.name]}
              />
            )}
            control={control}
            name={inputForm.name}
            rules={inputForm.validations}
          />
        )
      }
      if (inputForm.subtype === 'file') {
        return (
          <Controller
            key={index}
            render={({ field }: any) => (
              <FormInputFile
                {...field}
                label={inputForm.label}
                resetField={resetField}
                required={inputForm.required}
                disabled={inputForm.disabled}
                type={inputForm.subtype}
                placeholder={inputForm.placeholder}
                className={classNames(inputForm.className, errors[inputForm.name] ? 'invalid' : '')}
                errorText={errors[inputForm.name]?.message}
                isValid={!errors[inputForm.name]}
              />
            )}
            control={control}
            name={inputForm.name}
            rules={inputForm.validations}
          />
        )
      }
      return (
        <Controller
          key={index}
          render={({ field }: any) => (
            <FormInput
              {...field}
              label={inputForm.label}
              defaultValue={inputForm.defaultValue}
              required={inputForm.required}
              disabled={inputForm.disabled}
              type={inputForm.subtype}
              placeholder={inputForm.placeholder}
              className={classNames(inputForm.className, errors[inputForm.name] ? 'invalid' : '')}
              errorText={errors[inputForm.name]?.message}
              isValid={!errors[inputForm.name]}
            />
          )}
          control={control}
          name={inputForm.name}
          rules={inputForm.validations}
        />
      )
    }

    if (inputForm.type === 'checkbox') {
      if (['privacy_policy', 'privacy_policy_short'].includes(inputForm.subtype)) {
        const privacyPolicyLink = (
          <Link
            href="/ruta-de-politica-de-privacidad"
            target="_blank"
            className="text-blue-dark font-semibold hover:underline"
          >
            Política de Privacidad
          </Link>
        )
        const privacyPolicyText =
          inputForm.subtype === 'privacy_policy' ? (
            <p className="text-[14px] text-gray-dark">
              He leído la {privacyPolicyLink} y doy mi consentimiento para el tratamiento de mis datos personales.
            </p>
          ) : (
            <p className="text-[14px] text-gray-dark">He leído y acepto la {privacyPolicyLink} </p>
          )
        const shortId = inputForm.subtype === 'privacy_policy_short' ? '-short-' : ''
        return (
          <Controller
            key={index}
            render={({ field }: any) => (
              <FormGroup className={classNames(inputForm.className, errors[inputForm.name] ? 'invalid' : '')}>
                <Label htmlFor={inputForm.name + shortId + uuid} className="flex gap-x-2">
                  <input
                    type="checkbox"
                    checked={field.value}
                    id={inputForm.name + shortId + uuid}
                    {...field}
                    className={classNames('translate-y-[0.3rem] self-start', errors[inputForm.name] ? 'invalid' : '')}
                  />{' '}
                  {privacyPolicyText}
                </Label>
                <InputErrorText>{errors[inputForm.name]?.message}</InputErrorText>
              </FormGroup>
            )}
            control={control}
            name={inputForm.name}
            rules={inputForm.validations}
          />
        )
      }
      return (
        <Controller
          key={index}
          render={({ field }: any) => (
            <FormCheckbox
              {...field}
              label={inputForm.label}
              required={inputForm.required}
              disabled={inputForm.disabled}
              type={inputForm.subtype}
              placeholder={inputForm.placeholder}
              className={classNames(inputForm.className, errors[inputForm.name] ? 'invalid' : '')}
              errorText={errors[inputForm.name]?.message}
              isValid={!errors[inputForm.name]}
            />
          )}
          control={control}
          name={inputForm.name}
          rules={inputForm.validations}
        />
      )
    }

    if (inputForm.type === 'textarea') {
      return (
        <Controller
          key={index}
          render={({ field }: any) => (
            <FormTextarea
              {...field}
              label={inputForm.label}
              required={inputForm.required}
              disabled={inputForm.disabled}
              type={inputForm.subtype}
              placeholder={inputForm.placeholder}
              className={classNames(inputForm.className, errors[inputForm.name] ? 'invalid' : '')}
              errorText={errors[inputForm.name]?.message}
              isValid={!errors[inputForm.name]}
            />
          )}
          control={control}
          name={inputForm.name}
          rules={inputForm.validations}
        />
      )
    }

    if (inputForm.type === 'select') {
      return (
        <Controller
          key={index}
          render={({ field }: any) => (
            <FormSelect
              {...field}
              defaultValue={inputForm.defaultValue}
              label={inputForm.label}
              required={inputForm.required}
              disabled={inputForm.disabled}
              options={inputForm.options}
              type={inputForm.subtype}
              placeholder={inputForm.placeholder}
              className={classNames(inputForm.className, errors[inputForm.name] ? 'invalid' : '')}
              errorText={errors[inputForm.name]?.message}
              isValid={!errors[inputForm.name]}
            />
          )}
          control={control}
          name={inputForm.name}
          rules={inputForm.validations}
        />
      )
    }
    return null
  })
}

const FormStyled = ({
  InputsConfig,
  useWrapperForInputs = false,
  wrapperClassName = '',
  onSubmit,
  onCancel,
  submitText,
  className = '',
  submitClassName = '',
  submitTheme = 'primary',
  cancelMessage = '',
  cancelClassName = '',
  actionClassName = '',
  uuid = '',
  extraFromItem = null,
  yupValidationSchema
}: {
  InputsConfig: IInputConfiguration[]
  useWrapperForInputs?: boolean
  wrapperClassName?: string
  onSubmit: any
  onCancel?: any
  submitText: string
  className?: string
  submitClassName?: string
  submitTheme?: 'primary' | 'primary-outline' | 'secondary' | 'secondary-outline' | 'tertiary' | 'tertiary-outline'
  cancelMessage?: string
  cancelClassName?: string
  actionClassName?: string
  uuid?: string
  extraFromItem?: React.ReactNode
  yupValidationSchema?: any
}) => {
  const defaultValues = getDefaultValues(InputsConfig)

  const yupValidations: {
    resolver?: any
  } = {}

  if (yupValidationSchema) {
    yupValidations.resolver = yupResolver(yupValidationSchema)
  }
  const {
    handleSubmit,
    register,
    reset,
    resetField,
    control,
    formState: { errors }
  } = useForm({ defaultValues, ...yupValidations })
  const handleOnSubmit = (data: Record<string, any>) => {
    onSubmit(data, reset)
  }
  const formGroups = getFormGroups(InputsConfig, control, register, resetField, errors, uuid)

  const handleCancel = () => {
    reset()
    if (onCancel) onCancel()
  }
  return (
    // eslint-disable-next-line @typescript-eslint/no-misused-promises
    <form className={`flex flex-wrap gap-x-6 gap-y-5 ${className}`} onSubmit={handleSubmit(handleOnSubmit)}>
      {useWrapperForInputs ? (
        <div className={wrapperClassName}>{formGroups}</div>
      ) : (
        <>
          {formGroups}
          {extraFromItem}
        </>
      )}
      <div className={classNames('w-full flex flex-col gap-3', actionClassName)}>
        <Button className={classNames('w-full', submitClassName)} type="submit" theme={submitTheme}>
          {submitText}
        </Button>
        {cancelMessage && (
          <Button theme="primary-outline" className={cancelClassName} type="button" onClick={handleCancel}>
            {cancelMessage}
          </Button>
        )}
      </div>
    </form>
  )
}

export default FormStyled

const randomId = () => Math.random().toString(36).substring(7)
