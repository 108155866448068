export interface ISelectOption {
  value: string
  label: string
}

export interface IInputConfiguration {
  type: string
  subtype: string
  defaultValue?: string | number | boolean
  name: string
  label: string
  className: string
  placeholder: string
  required: boolean
  disabled?: boolean
  showLabel: boolean
  validations: {
    required?: { value: boolean; message: string }
    minLength?: { value: number; message: string }
    maxLength?: { value: number; message: string }
    pattern?: { value: RegExp; message: string }
  }
  options?: ISelectOption[]
}

export interface IInputConfigurationPartial {
  type?: string
  subtype?: string
  defaultValue?: string | number | boolean
  name: string
  label?: string
  className?: string
  placeholder?: string
  required?: boolean
  disabled?: boolean
  showLabel?: boolean
  validations?: {
    required?: { value: boolean; message: string }
    minLength?: { value: number; message: string }
    maxLength?: { value: number; message: string }
    pattern?: { value: RegExp; message: string }
  }
  options?: ISelectOption[]
}

export type InputName =
  | 'full_name'
  | 'name'
  | 'last_name'
  | 'company'
  | 'position'
  | 'phone'
  | 'fix_number_phone'
  | 'email'
  | 'message'
  | 'role'
  | 'residence'
  | 'accept_terms'
  | 'cv_resume'
  | 'salary_expectation'
  | 'identification_card'
  | 'years_of_experience'
  | 'has_more_than_2_years_of_experience'
  | 'aimed_at'
  | 'username'
  | 'password'
  | 'oldPassword'
  | 'newPassword'
  | 'confirmPassword'
  | 'token'
  | 'photo'
  | 'university'

export const AvailableInputConfigurations: { [key in InputName]: IInputConfiguration } = {
  full_name: {
    type: 'input',
    subtype: 'text',
    defaultValue: '',
    name: 'full_name',
    label: 'Full Name',
    className: 'flex-1 min-w-32',
    placeholder: 'Full Name',
    required: true,
    showLabel: false,
    validations: {
      minLength: {
        value: 2,
        message: 'The name must have at least 2 characters'
      },
      required: { value: true, message: 'Full name is required' }
    }
  },
  name: {
    type: 'input',
    subtype: 'text',
    defaultValue: '',
    name: 'name',
    label: 'Name',
    className: 'flex-1 min-w-32',
    placeholder: 'Name',
    required: true,
    showLabel: false,
    validations: {
      minLength: {
        value: 2,
        message: 'The name must have at least 2 characters'
      },
      required: { value: true, message: 'Name is required' }
    }
  },
  last_name: {
    type: 'input',
    subtype: 'text',
    defaultValue: '',
    name: 'last_name',
    label: 'Last Name',
    className: 'flex-1 min-w-32',
    placeholder: 'Last Name',
    required: true,
    showLabel: false,
    validations: {
      minLength: {
        value: 2,
        message: 'Must have at least 2 characters'
      },
      required: { value: true, message: 'Last name is required' }
    }
  },
  company: {
    type: 'input',
    subtype: 'text',
    defaultValue: '',
    name: 'company',
    label: 'Company',
    className: 'flex-1 min-w-32',
    placeholder: 'Company',
    required: true,
    showLabel: false,
    validations: {
      required: { value: true, message: 'Company is required' }
    }
  },
  position: {
    type: 'input',
    subtype: 'text',
    defaultValue: '',
    name: 'position',
    label: 'Position',
    className: 'flex-1 min-w-32',
    placeholder: 'Position',
    required: false,
    showLabel: false,
    validations: {}
  },
  phone: {
    type: 'input',
    subtype: 'tel',
    defaultValue: '',
    name: 'phone',
    label: 'Phone',
    className: 'w-full min-w-full',
    placeholder: 'Phone',
    required: true,
    showLabel: false,
    validations: {
      required: { value: true, message: 'Phone is required' },
      pattern: {
        value: /^[0-9\s+]*$/,
        message: 'The phone number is not valid'
      },
      minLength: {
        value: 12,
        message: 'The phone number must have at least 12 digits'
      },
      maxLength: {
        value: 14,
        message: 'The phone number must have a maximum of 14 digits'
      }
    }
  },
  fix_number_phone: {
    type: 'input',
    subtype: 'text',
    defaultValue: '',
    name: 'fix_number_phone',
    label: 'Landline Number',
    className: 'flex-1 min-w-32',
    placeholder: 'Landline Number',
    required: true,
    showLabel: false,
    validations: {
      pattern: {
        value: /^[0-9\s+]*$/,
        message: 'The phone number is not valid'
      },
      required: { value: true, message: 'Landline number is required' }
    }
  },
  email: {
    type: 'input',
    subtype: 'text',
    defaultValue: '',
    name: 'email',
    label: 'Email',
    className: 'w-full min-w-full',
    placeholder: 'Email',
    required: true,
    showLabel: false,
    validations: {
      required: { value: true, message: 'Email is required' },
      pattern: {
        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
        message: 'The email address is not valid'
      }
    }
  },
  message: {
    type: 'textarea',
    subtype: 'textarea',
    defaultValue: '',
    name: 'message',
    label: 'Message',
    className: 'w-full min-w-full',
    placeholder: 'Message',
    required: false,
    showLabel: false,
    validations: {}
  },
  residence: {
    type: 'input',
    subtype: 'text',
    defaultValue: '',
    name: 'residence',
    label: 'Residence',
    className: 'w-full min-w-full',
    placeholder: 'Place of Residence (City / State)',
    required: false,
    showLabel: false,
    validations: {}
  },
  role: {
    type: 'select',
    subtype: 'select',
    defaultValue: '',
    name: 'role',
    label: 'Role',
    className: 'w-full min-w-full',
    placeholder: 'Role',
    required: true,
    showLabel: false,
    validations: {
      required: { value: true, message: 'Role is required' }
    },
    options: [
      {
        value: '',
        label: 'Role'
      },
      {
        value: '1',
        label: 'Admin'
      },
      {
        value: '2',
        label: 'University Partner'
      },
      {
        value: '3',
        label: 'University User'
      }
    ] as ISelectOption[]
  },
  accept_terms: {
    type: 'checkbox',
    subtype: 'privacy_policy',
    defaultValue: false,
    name: 'accept_terms',
    label: 'I have read the Privacy Policy and consent to the processing of my personal data.',
    className: 'w-full min-w-full',
    placeholder: '',
    required: true,
    showLabel: true,
    validations: {
      required: { value: true, message: 'You must accept the Privacy Policy' }
    }
  },
  cv_resume: {
    type: 'input',
    subtype: 'file',
    defaultValue: undefined,
    name: 'cv_resume',
    label: 'CV',
    className: 'w-full min-w-full',
    placeholder: 'Attach CV',
    required: true,
    showLabel: false,
    validations: {
      required: { value: true, message: 'CV is required' }
    }
  },
  salary_expectation: {
    type: 'input',
    subtype: 'text',
    defaultValue: '',
    name: 'salary_expectation',
    label: 'Desired Monthly Salary (After Tax)',
    className: 'w-full min-w-full',
    placeholder: 'Desired Monthly Salary (After Tax)',
    required: false,
    showLabel: false,
    validations: {}
  },
  identification_card: {
    type: 'input',
    subtype: 'text',
    defaultValue: '',
    name: 'identification_card',
    label: 'Professional ID Number',
    className: 'w-full min-w-full',
    placeholder: 'Professional ID Number',
    required: false,
    showLabel: false,
    validations: {}
  },
  years_of_experience: {
    type: 'input',
    subtype: 'text',
    defaultValue: '',
    name: 'years_of_experience',
    label: 'Years of Experience in a Similar Position',
    className: 'w-full min-w-full',
    placeholder: 'Years of Experience in a Similar Position',
    required: false,
    showLabel: false,
    validations: {}
  },
  has_more_than_2_years_of_experience: {
    type: 'checkbox',
    subtype: 'has_more_than_2_years_of_experience',
    defaultValue: false,
    name: 'has_more_than_2_years_of_experience',
    label: 'Do you have 2 or more verifiable work references?',
    className: 'w-full min-w-full',
    placeholder: '',
    required: false,
    showLabel: true,
    validations: {}
  },
  aimed_at: {
    type: 'select',
    subtype: 'select',
    defaultValue: '',
    name: 'aimed_at',
    label: 'This Course is For:',
    className: 'w-full min-w-full',
    placeholder: 'This Course is For:',
    required: true,
    showLabel: false,
    validations: {
      required: { value: true, message: 'This field is required' }
    },
    options: [
      {
        value: '',
        label: 'This Course is For:'
      },
      {
        value: 'company',
        label: 'Company'
      },
      {
        value: 'individual',
        label: 'Individual'
      }
    ]
  },
  university: {
    type: 'select',
    subtype: 'select',
    defaultValue: '',
    name: 'university',
    label: 'University',
    className: 'w-full min-w-full',
    placeholder: 'University',
    required: true,
    showLabel: true,
    validations: {},
    options: [
      {
        value: '',
        label: 'Select a university'
      },
      {
        value: '1',
        label: 'University of California'
      },
      {
        value: '2',
        label: 'Ohio State University'
      }
    ]
  },
  photo: {
    type: 'input',
    subtype: 'file',
    defaultValue: undefined,
    name: 'photo',
    label: 'Upload Logo',
    className: 'w-full min-w-full',
    placeholder: 'Upload Logo',
    required: false,
    showLabel: false,
    validations: {}
  },
  username: {
    type: 'input',
    subtype: 'text',
    defaultValue: '',
    name: 'username',
    label: 'Email',
    className: 'w-full min-w-full',
    placeholder: 'Email',
    required: true,
    showLabel: false,
    validations: {
      required: { value: true, message: 'Email is required' },
      pattern: {
        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
        message: 'The email address is not valid'
      }
    }
  },
  password: {
    type: 'input',
    subtype: 'password',
    defaultValue: '',
    name: 'password',
    label: 'Password',
    className: 'w-full min-w-full',
    placeholder: 'Password',
    required: true,
    showLabel: false,
    validations: {
      required: { value: true, message: 'Password is required' }
    }
  },
  oldPassword: {
    type: 'input',
    subtype: 'password',
    defaultValue: '',
    name: 'oldPassword',
    label: 'Old Password',
    className: 'w-full min-w-full',
    placeholder: 'Old Password',
    required: true,
    showLabel: false,
    validations: {
      required: { value: true, message: 'Old password is required' }
    }
  },
  newPassword: {
    type: 'input',
    subtype: 'password',
    defaultValue: '',
    name: 'newPassword',
    label: 'New Password',
    className: 'w-full min-w-full',
    placeholder: 'New Password',
    required: true,
    showLabel: false,
    validations: {
      required: { value: true, message: 'New password is required' }
    }
  },
  confirmPassword: {
    type: 'input',
    subtype: 'password',
    defaultValue: '',
    name: 'confirmPassword',
    label: 'Confirm Password',
    className: 'w-full min-w-full',
    placeholder: 'Confirm Password',
    required: true,
    showLabel: false,
    validations: {
      required: { value: true, message: 'Confirm password is required' }
    }
  },
  token: {
    type: 'input',
    subtype: 'text',
    defaultValue: '',
    name: 'token',
    label: 'Token',
    className: 'w-full min-w-full',
    placeholder: 'Token',
    required: true,
    showLabel: false,
    validations: {
      required: { value: true, message: 'Token is required' }
    }
  }
}

export type RequestedFormsConfigType = Array<InputName | IInputConfigurationPartial>
export const useMergeFormsConfig = (requestedFormsConfig: RequestedFormsConfigType) => {
  const mergedFormsConfig = requestedFormsConfig.map((formConfig: InputName | IInputConfigurationPartial) => {
    if (typeof formConfig === 'string') {
      return { ...AvailableInputConfigurations[formConfig] }
    }
    const inputName = formConfig.name as InputName
    return { ...AvailableInputConfigurations[inputName], ...formConfig }
  })
  return mergedFormsConfig
}
