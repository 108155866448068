'use client'
import React, { ChangeEvent, useState } from 'react'
import style from './FormTextarea.module.scss'
import FormGroup from '@/components/atoms/forms/FormGroup'
import { Label } from '@/components/atoms/forms/Label'
import InputGroup from '@/components/atoms/forms/InputGroup'
import { classNames } from '@/components/shared/classNames'
import InputErrorText from '@/components/atoms/forms/InputErrorText'

interface FormTextareaProps extends React.TextareaHTMLAttributes<HTMLTextAreaElement> {
  name: string
  label: string | React.ReactNode
  placeholder: string
  value: string
  isValid?: boolean
  errorText?: string
  required?: boolean
  onChange?: (event: ChangeEvent<HTMLTextAreaElement>) => any
}

const FormTextarea = React.forwardRef(
  (
    { name, label, placeholder, value, isValid = true, required, className, errorText, onChange }: FormTextareaProps,
    ref
  ) => {
    const [isFocused, setIsFocused] = useState(false)

    const handleFocus = () => {
      setIsFocused(true)
    }

    const handleBlur = () => {
      setIsFocused(false)
    }

    return (
      <FormGroup className={className}>
        <Label isFocused={isFocused} isValid={isValid} hidden={Boolean(label)} htmlFor={name}>
          {label}
        </Label>
        <InputGroup>
          <textarea
            className={classNames(style.textarea, isValid ? '' : style['textarea--invalid'])}
            id={name}
            name={name}
            value={value}
            onFocus={handleFocus}
            onBlur={handleBlur}
            onChange={onChange}
            placeholder={isFocused ? '' : required ? '* ' + placeholder : placeholder}
          />
          <InputErrorText>{errorText}</InputErrorText>
        </InputGroup>
      </FormGroup>
    )
  }
)

FormTextarea.displayName = 'FormTextarea'
export default FormTextarea
